import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AdminSetting } from '../AllModels';
import { createAdminSettingURL, fileUpload, getAllAdminSettingURL, getByIdAdminSettingURL, updateAdminSettingURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-adminsetting',
  templateUrl: './adminsetting.component.html',
  styleUrls: ['./adminsetting.component.css']
})
export class AdminsettingComponent implements OnInit {
  AdminSettingModel = new AdminSetting();
  adminSettingForm: FormGroup;
  isEdit: boolean = true;
  hide = true;
  filetoUpload: File = null;
  errormsg: string;


  constructor( private formBuilder: FormBuilder ,private httpService: HttpmethodsService, public router:Router, public datepipe : DatePipe,private toastr:ToastrManager) { }

  
  displayedColumns: string[] = ['Sr.No.', 'UPI ID', 'Withdraw Amount','Add Amount','Apk Version', 'Action'];
  dataSource = new MatTableDataSource<AdminSetting>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.adminSettingForm = this.formBuilder.group({
      withdrawAmount: new FormControl(''),
      addAmount: new FormControl('', [Validators.required,]),
      adminWithdrawAmount: new FormControl(''),
      adminAddAmount: new FormControl('', [Validators.required,]),
      upiId: new FormControl('', [Validators.required,]),
      apkVersion: new FormControl(''),
      qrCode:new FormControl(''),
    })
    this.getAllAdmin();
  }

  
  SaveAdmin() {
    if (this.isEdit) {
        this.httpService.postRequest(createAdminSettingURL, this.AdminSettingModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllAdmin();
            this.adminSettingForm.reset();
         
            
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
    }
    else {
        this.httpService.putRequest(updateAdminSettingURL, this.AdminSettingModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
            this.getAllAdmin();
            this.adminSettingForm.reset();
           
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
   
    }  
  }

  getAllAdmin() {
    this.httpService.getRequest(getAllAdminSettingURL).subscribe((data: any) => {
      if (data) {
        this.AdminSettingModel.adminSettingId = data[0].adminSettingId;
        this.getAdminById(this.AdminSettingModel.adminSettingId);
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getAdminById(adminSettingId) {
    
    this.httpService.getRequest(getByIdAdminSettingURL + "/" + adminSettingId).subscribe((data: any) => {
      this.AdminSettingModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.AdminSettingModel.qrCode = data.path;
      }
      else {
        this.toastr.errorToastr(data.path, 'error!', { timeout: 500 });
      }
    })
  }

}
